import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';

export default class FreeSpinsAddState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_WINDOW_SHOWED, this.complete.bind(this));
  }

  start() {
    super.start();
    EntryPoint.AutoPlaySettings.active = false;
    window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
  }

  complete() {
    if (this.active) {
      super.complete();
    }
  }
}
