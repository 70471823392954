import eEventTypes from 'Engine/enums/eBaseEventTypes';

export default {
  EET_BET_CHANGED: 'model:betChanged',
  EET_DATA_UPDATED: 'model:getServerData',

  EET_SPIN_CLICK: 'ui:spinClick',
  EET_PAYTABLE_CLICK: 'ui:paytableClick',
  EET_PAYTABLE_CHANGE_PAGE: 'ui:paytableChangePage',
  EET_PAYTABLE_BACK_CLICK: 'ui:paytableBackClick',
  EET_SETTINGS_CLICK: 'ui:settingsClick',
  EET_POPUP_AUTO_PLAY: 'ui:PopupAutoplay',
  EET_PURCHASE_BTN_CLICK: 'ui:openPurchaseClick',

  EET_BUY_BONUS: 'bonusGame:buy',
  EET_PURCHASE_CONFIRM: 'bonusGame:confirm',

  EET_SPIN_RECEIVED: 'spinReceived',
  EET_SPIN_NEXT_STAGE: 'spinNextStage',
  EET_REELS_STOPPED: 'reelsContainer:reelsStopped',
  EET_ADD_MIDDLE_WIN_AMOUNT: 'addMiddleWinAmount',

  EET_STOP_CLICKED: 'stopClicked',
  EET_CAN_STOP: 'canStop',

  EET_IDLE_STATE_START: 'stateStart:idleState',
  EET_IDLE_STATE_END: 'stateEnd:idleState',

  EET_ROLLING_STATE_START: 'stateStart:requestRollingState',
  EET_STOP_ROLLING_STATE_START: 'stateStart:stopRollingState',

  EET_FREESPINS_ADD_START: 'stateStart:FreespinsAddState',
  EET_FREESPINS_ADD_END: 'stateEnd:FreespinsAddState',

  EET_FREESPINS_IN_START: 'stateStart:FreespinsInState',
  EET_FREESPINS_IN_END: 'stateEnd:FreespinsInState',

  EET_FREESPINS_OUT_START: 'stateStart:FreespinsOutState',
  EET_FREESPINS_OUT_END: 'stateEnd:FreespinsOutState',

  EET_START_SCREEN_START: 'stateStart:startScreenState',
  EET_START_SCREEN_END: 'stateEnd:startScreenState',

  EET_WAIT_USER_INTERACTION_START: 'stateStart:waitUserInteractionState',
  EET_WAIT_USER_INTERACTION_END: 'stateEnd:waitUserInteractionState',

  EET_FREESPINS_WINDOW_SHOWED: 'freeSpinsWindowShowed',

  EET_SHOW_EXPRESSION: 'showExpression',
  EET_SEND_BOMB_REQUEST: 'sendBombRequest',
  EET_USE_BOMB: 'useBomb',
  EET_BOMB_ACTION: 'bombAction',
  EET_BOMB_ACTION_END: 'bombActionEnd',
  EET_BOMB_TIME_END: 'bombTimeEnd',
  EET_FIELD_IDLE: 'fieldIdle',
  EET_FIELD_DISPLAY_OBJECT: 'fieldDisplayObject',

  EET_COLOR_BOMB_STATE_START: 'stateStart:colorBombActionState',
  EET_SEND_CELLS_FOR_COLOR_BOMB: 'sendCellsForColorBomb',
  EET_COLOR_BOMB_RAY_START: 'colorBombRayStart',
  EET_COLOR_BOMB_RAY_END: 'colorBombRayEnd',
  EET_COLOR_BOMB_ACTION_COMPLETE: 'colorBombActionComplete',
  EET_COMBINATION_PLAYING: 'combinationPlaying',
  EET_FIELD_STOPPED: 'ControllerField:fieldStopped',

  EET_SEND_MULTIPLIER_TEXT_FIELD: 'sendMultiplierTextField',
  ...eEventTypes
}
