export default {
  EAT_BACKGROUND: 'background',
  EAT_FRUITS: 'fruits',
  EAT_EXPRESSIONS: 'expressions',
  EAT_BOMB: 'bomb',
  EAT_PROGRESS: 'progress',
  EAT_COLOR_BOMB_RAY: 'colorBombRay',
  EAT_PURCHASE: 'purchase',
  EAT_PURCHASE_BUTTON: 'purchaseButton',
  EAT_BIG_WINS: 'bigWins',
  EAT_COINS: 'coins'
}
