import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import GameModel from '../models/gameModel';

export default class ColorBombIterationState extends BaseState {
  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_FIELD_STOPPED, this.onComplete, this)
  }

  start() {
    super.start();
    GameModel.bombStage();
  }

  onComplete() {
    if (this.active) {
      this.complete();
    }
  }
}
