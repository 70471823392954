import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eEventTypes from '../../enums/eEventTypes';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';

export default class ControllerPaytableContent extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.currentFrame = 1;
    this.reset = this.reset.bind(this);
    this.changeFrame = this.changeFrame.bind(this);
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE_BACK_CLICK, this.reset);
    GlobalDispatcher.add(eEventTypes.EET_PAYTABLE_CHANGE_PAGE, this.changeFrame);
  }

  setMultipliers() {
    if (this.currentFrame !== 6) return;

    for (let i = 1; i < Object.keys(EntryPoint.GameModel.paytableInfo).length + 1; i++) {
      let element = this.children[0].getChildByName(`paytable_p6_coefs_${i}`);
      let coefs = [...EntryPoint.GameModel.paytableInfo[`${i + 1}`]];
      coefs.forEach((coef, i) => coefs[i] = `×${coef}`);
      element.text = coefs.reverse().join('\n');
    }
  }

  changeFrame(event) {
    this.currentFrame = event.params;
    this.goToFrame(this.currentFrame);
    this.setMultipliers();
    this.tryToStartGameExample();
  }

  tryToStartGameExample() {
    if (this.currentFrame !== 2) return;

    this.animationPlace = this.children[0].getChildByName('animationPlace');
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_FRUITS);
    this.animation.state.setAnimation(0, 'demo_gameplay2', true);
    this.animationPlace.addChild(this.animation);
  }

  reset() {
    this.currentFrame = 1;
    this.goToFrame(this.currentFrame);
  }

  destroy(opts) {
    GlobalDispatcher.remove(eEventTypes.EET_PAYTABLE_BACK_CLICK, this.reset);
    GlobalDispatcher.remove(eEventTypes.EET_PAYTABLE_CHANGE_PAGE, this.changeFrame);
    super.destroy(opts);
  }
}
