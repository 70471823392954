import eSoundType from './eSoundType';

export default {
  [eSoundType.EST_SFX]: 0.5,
  [eSoundType.EST_SYMBOL_STOP]: 0.4,
  [eSoundType.EST_BIG_WIN]: 0.6,
  [eSoundType.EST_BIG_WIN_BACKGROUND]: 0.5,
  [eSoundType.EST_MEGA_WIN]: 0.6,
  [eSoundType.EST_EPIC_WIN]: 0.6,
  [eSoundType.EST_COLOR_BOMB_RAY]: 0.6,
  [eSoundType.EST_COLOR_BOMB_COLLAPSE]: 1,
  [eSoundType.EST_FIELD_BOMB]: 1,
  [eSoundType.EST_WIN_FREESPINS]: 0.6,
  [eSoundType.EST_FREESPINS_TOTAL_WIN]: 0.6,
  [eSoundType.EST_INCREASE_PROGRESS]: 0.4,
  [eSoundType.EST_SIMPLE_SYMBOL_DESTROY_5]: 1,
  [eSoundType.EST_SIMPLE_SYMBOL_DESTROY_6]: 1,
  [eSoundType.EST_SIMPLE_SYMBOL_DESTROY_7]: 1,
  [eSoundType.EST_SIMPLE_SYMBOL_DESTROY_8]: 1,
}
