import flashlib from '../../../assets/exported/game/FlashLib.json';

import starTexture from '../../../assets/images/star_particle.png';
import dotTexture from '../../../assets/images/dot.png';

import UbuntuBold from '../../../assets/fonts/UBUNTU-B.TTF';
import UbuntuRegular from '../../../assets/fonts/UBUNTU-R.TTF';

import Config from './scaleConfig';
import eAnimationTypes from '../enums/eAnimationTypes';
import eSoundType from '../sounds/eSoundType';
import { getAssetsScale } from 'Engine/utils/getAssetsScale';

// import assets from '../../../assets/exported/game/FlashLibAssets';

const availableScales = ['0.7','0.75','0.8','0.9'];
const scale = getAssetsScale({
  availableScales,
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
});

const assets = `flashlib/game/AssetsCombined1@${scale}x.json`;

const config = {
  assets: [
    { name: 'GameAssets', path: assets, extension: 'json' },
    { name: 'GameFlashLib', path: flashlib, extension: 'json' },
    { name: eAnimationTypes.EAT_FRUITS, path: 'animations/symbols_dew/juicycrush_symbols.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BACKGROUND, path: 'animations/FB_bg_animation_pro_dew/bg_animation.json', extension: 'json' },
    { name: eAnimationTypes.EAT_EXPRESSIONS, path: 'animations/fb_expressions_animation_DEW/expressions_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BOMB, path: 'animations/fb_tnt_DEW/fb_tnt.json', extension: 'json' },
    { name: eAnimationTypes.EAT_COLOR_BOMB_RAY, path: 'animations/color_bomb_ray/ray.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PROGRESS, path: 'animations/fb_progress_start_screen_pro_DEW/fb_progress_start_screen_pro.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PURCHASE, path: 'animations/j_c_Bonus_purchase_DEW/purchase_bonuses.json', extension: 'json' },
    { name: eAnimationTypes.EAT_PURCHASE_BUTTON, path: 'animations/bonus_purchase_button_pro_DEW/button_bones_pro.json', extension: 'json' },
    { name: 'jc_font', path: 'fonts/bitmap/jc_font.xml', extension: 'xml' },
    { name: 'starsParticle', path: starTexture, extension: 'png' },
    { name: 'dotParticle', path: dotTexture, extension: 'png' },
  ],
  delayedAssets: [
    { name: eAnimationTypes.EAT_BIG_WINS, path: 'animations/big_wins/DWB.json', extension: 'json' },
    { name: eAnimationTypes.EAT_COINS, path: 'animations/coins/skeleton.json', extension: 'json' },
  ],
  fonts: [
    {
      name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    }
  ],
  sounds: eSoundType,
};


export default config;
