import LocalizeTextField from 'Engine/base/localization/LocalizeTextField';

export default class ControllerTextField extends LocalizeTextField {
  constructor(data, displayData) {
    super(data, displayData);
    this._positionArguments = [];
  }

  fitSize() {
    super.fitSize(true, true);
  }

  correctPosition = (...args) => {
    if (args.length) {
      this._positionArguments = args;
    }

    super.correctPosition(...this._positionArguments);
  }
}
