import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import { gsap } from 'gsap';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eSoundVolume from '../../sounds/eSoundVolume';

export default class ControllerColorBomb extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._used = false;
    this._active = false;
    this._rayDefaultLength = 275;
    this._minRaysCount = 6;
    this._rayDelay = 200;
    this._rays = [];
    this._raysTimeouts = [];
    this._restartRays = false;
    this._explosionDelay = 1000;
    this.init();
    this.initAnimation();
    this._initRaysAnimation()
  }

  init() {
    this.disabledBomb = this.getChildByName('disabledBomb');
    this.stroke = this.getChildByName('stroke');
  }

  initAnimation() {
    if (this.animation) this.animation.destroy({ children: true });

    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_PROGRESS);
    this.animation.visible = false;
    this.addChild(this.animation);
  }

  changeFrame(value) {
    this.goToFrame(value);
    this.init();
    this.initAnimation();
    this._initRaysAnimation();
  }

  async start() {
    this._used = true;
    this._active = false;

    gsap.to(this.animation.scale, { x: 1.6, y: 1.6, duration: 1 }).play();
    this.animation.state.setAnimation(0, 'mine_start', false);
    this.animation.state.addAnimation(0, 'mine_action', true, 0);
    await this._raysAction();
    this._explosion();
  }

  tryToActivate() {
    if (this._used || this.active) return;
    this.active = true;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  _explosion() {
    this._explosionTimeout = setTimeout(() => {
      this._explosionTimeout = null
      SoundManager.play(eSoundType.EST_COLOR_BOMB_COLLAPSE, eSoundVolume[eSoundType.EST_COLOR_BOMB_COLLAPSE], false);
      this.animation.state.setAnimation(0, 'mine_action_explosion', false);
      this.animation.state.addListener({
        complete: () => {
          this.animation.state.clearListeners();
          GlobalDispatcher.dispatch(eEventTypes.EET_COLOR_BOMB_ACTION_COMPLETE);
          this._raysTimeouts = [];
        }
      });
      gsap.to(this.animation.scale, { x: 1, y: 1, duration: 0.2 }).play();
      this.stroke.goToFrame(2);
    }, this._explosionDelay);
  }

  _raysAction() {
    return new Promise(resolve => {
      EntryPoint.GameModel.colorBombField.forEach((position, index) => {
        if (!EntryPoint.GameModel.cells[position.x][position.y]) {
          index === EntryPoint.GameModel.colorBombField.length - 1 && resolve();
          return;
        }

        const coordsSymbol = this.toLocal({ x: 0, y: 0 }, EntryPoint.GameModel.cells[position.x][position.y]);
        const timeout = setTimeout(() => {
          const ray = this._rays.shift() || this._initRay();
          this._animateRay(coordsSymbol, position, ray);
          index === EntryPoint.GameModel.colorBombField.length - 1 && resolve();
        }, this._rayDelay * index);

        this._raysTimeouts.push(timeout);
      });
    });
  }

  _initRaysAnimation() {
    for (let i = 0; i < this._rays.length; i++) {
      this._rays[i].destroy({ children: true });
    }
    this._rays = [];
    for (let i = 0; i < this._minRaysCount; i++) {
      this._initRay();
    }
  }

  _initRay() {
    const rayAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_COLOR_BOMB_RAY);
    rayAnimation.visible = false;
    rayAnimation.pivot.x = this._rayDefaultLength;
    rayAnimation.scale.y = 3;
    rayAnimation.state.timeScale = 1;
    this._rays.push(rayAnimation);
    this.addChildAt(rayAnimation, 0);
    return rayAnimation;
  }

  _animateRay(coordsSymbol, symbolPosition, ray) {
    const rayLength = Math.sqrt(coordsSymbol.x ** 2 + coordsSymbol.y ** 2);
    ray.scale.x = rayLength / this._rayDefaultLength;
    ray.scale.x = coordsSymbol.x > 0 ? -ray.scale.x : ray.scale.x;
    ray.rotation = Math.atan(coordsSymbol.y / coordsSymbol.x);
    ray.state.setAnimation(0, 'animation', false);
    ray.visible = true;
    SoundManager.play(eSoundType.EST_COLOR_BOMB_RAY, eSoundVolume[eSoundType.EST_COLOR_BOMB_RAY], false);
    ray.state.addListener({
      complete: () => {
        GlobalDispatcher.dispatch(eEventTypes.EET_COLOR_BOMB_RAY_END, symbolPosition);
        ray.state.clearListeners();
        ray.visible = false;
        this._rays.push(ray);
      }
    });
  }

  set active(value) {
    this._active = value;
    gsap.killTweensOf(this.animation);

    if (this._active) {
      this.animation.visible = true;
      this.disabledBomb.visible = false;
      this.animation.state.setAnimation(0, 'mine_appear', false);
      this.animation.state.addAnimation(0, 'mine_idle', true, 0);
    } else {
      this.disabledBomb.visible = true;
      this.animation.visible = false;
      this.stroke.goToFrame(1);
      this._used = false;
    }
  }

  set used(value) {
    this._used = value;
    if (!this._used) return;

    gsap.killTweensOf(this.animation);
    this.disabledBomb.visible = false;
    this.animation.visible = true;
    this.animation.state.setAnimation(0, 'mine_action_explosion', false);
    this.stroke.goToFrame(2);
  }

  set restart(value) {
    this._restartRays = value;
    if (this._restartRays) this.start();
  }

  get active() {
    return this._active;
  }

  get used() {
    return this._used;
  }

  get restart() {
    return !!this._raysTimeouts.length || this._explosionTimeout;
  }

  destroy(opts) {
    this._raysTimeouts.forEach(timeout => clearTimeout(timeout));
    clearTimeout(this._explosionTimeout);
    super.destroy(opts);
  }
}
