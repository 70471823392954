import BaseButton from 'Engine/base/ui/BaseButton';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';

export default class BaseGameButton extends BaseButton {
  constructor(...arg) {
    super(...arg);
    this.on('pointertap', () => SoundManager.play(eSoundType.EST_BUTTON_CLICK))
  }
}
