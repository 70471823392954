import GameConfig from '../../configs/gameConfig';
import EntryPoint from 'Engine/EntryPoint';
import { gsap } from 'gsap';
import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import eLibraryItems from '../../enums/eLibraryItems';
import { getRandom } from 'Engine/utils/getRandom';
import eSymbolTypes from '../../enums/eSymbolTypes';
import SoundManager from '../../libs/game-engine/src/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eSoundVolume from '../../sounds/eSoundVolume';

export default class ControllerField extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._cells = [];
    this._fallAnimations = [];
    this._prevStageFallAnimations = [];
    this._itemsToDestroyOnIterationEnd = [];
    this._coordsX = {};
    this._coordsY = {};
    this._offsetY = GameConfig.LINES_COUNT * (GameConfig.SYMBOLS.height + GameConfig.SYMBOLS.topOffset) + GameConfig.SYMBOLS.height / 2;

    this.init();
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_STOP_ROLLING_STATE_START, this.onNextSpinStage, this);
    GlobalDispatcher.add(eEventTypes.EET_SPIN_NEXT_STAGE, this.onNextSpinStage, this);
    GlobalDispatcher.add(eEventTypes.EET_ROLLING_STATE_START, this.start, this);
    GlobalDispatcher.add(eEventTypes.EET_FIELD_STOPPED, this.onFieldStopped, this);
    GlobalDispatcher.add(eEventTypes.EET_BOMB_ACTION, this.onBombAction, this);
    GlobalDispatcher.add(eEventTypes.EET_FIELD_IDLE, this.playIdleAnimationForRandomSymbolsType, this);
    GlobalDispatcher.add(eEventTypes.EET_COLOR_BOMB_RAY_END, this.onColorBombRayEnd, this);
  }

  init() {
    this._container = new PIXI.Container();
    this.addChild(this._container);

    const mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRect(0, 0, this.width / this.scale.x, this.height / this.scale.y);
    mask.endFill();
    this.addChild(mask);
    this._container.mask = mask;

    this._topContainer = new PIXI.Container();
    this.addChild(this._topContainer);

    for (let i = 0; i < GameConfig.COLUMNS_COUNT; i++) {
      const column = [];
      for (let j = 0; j < GameConfig.LINES_COUNT; j++) {
        const item = FlashLib.createItemFromLibrary(eLibraryItems.ELI_FRUIT, 'GameFlashLib');
        const id = getRandom(2, 7);
        item.changeSymbol(id);
        column.push(item);
        this._container.addChild(item);
        item.x = (GameConfig.SYMBOLS.width + GameConfig.SYMBOLS.sideOffset) * i + GameConfig.SYMBOLS.width / 2;
        item.y = (GameConfig.SYMBOLS.height + GameConfig.SYMBOLS.topOffset) * j + (GameConfig.SYMBOLS.height + GameConfig.SYMBOLS.topOffset) / 2;

        this._coordsX[i] = item.x;
        this._coordsY[j] = item.y;
      }

      this._cells.push(column);
    }
  }

  start() {
    this._animatePrevStageFalling();
  }

  async onNextSpinStage() {
    await this._animateFallingInEmptySpaces();
    await this.startCombinations();
    EntryPoint.GameModel.nextStage();
  }

  playIdleAnimationForRandomSymbolsType() {
    const id = getRandom(1, Object.keys(eSymbolTypes).length);

    this._cells.forEach(column => column.forEach(symbol => {
      if (symbol.id === id) symbol.playIdleAnimation();
    }));
  }

  async startCombinations() {
    let promises = [];
    const combinations = EntryPoint.GameModel.combinations;
    let delay = 0;

    for (let i = 0; i < combinations.length; i++) {
      const combination = combinations[i];
      const promise = this._animateCombo(combination, i, delay);
      promises.push(promise);
      delay += 1400;
    }

    await Promise.all(promises);
    this.clearMemory();
  }

  clearMemory() {
    this._itemsToDestroyOnIterationEnd.forEach(item => item.destroy({ children: true }));
    this._itemsToDestroyOnIterationEnd = [];
  }

  async onBombAction() {
    const promises = [];
    for (let i = 0; i < this._cells.length; i++) {
      const column = this._cells[i];

      for (let j = 0; j < column.length; j++) {
        const item = column[j];
        if (!item) continue;
        const promise = new Promise(resolve => {
          item.on('winAnimationComplete', resolve);
        });
        promises.push(promise);
        item.playWinAnimation();
        this._itemsToDestroyOnIterationEnd.push(item);
        delete column[j];
      }
    }

    await Promise.all(promises);
    GlobalDispatcher.dispatch(eEventTypes.EET_BOMB_ACTION_END);
  }

  onColorBombRayEnd(data) {
    const position = data.params;
    const symbol = this._cells[position.x][position.y];
    if (!symbol) return;
    symbol.playWinAnimation();
    gsap.killTweensOf(symbol);
    this._itemsToDestroyOnIterationEnd.push(symbol);
    delete this._cells[position.x][position.y];
  }

  onFieldStopped() {
    EntryPoint.GameModel.cells = this._cells;
    EntryPoint.GameModel.spinEndTimestamp = Date.now();
  }

  _animateCombo(combination, i, delay) {
    return new Promise(resolveCombination => {
      setTimeout(async () => {
        GlobalDispatcher.dispatch(eEventTypes.EET_COMBINATION_PLAYING, combination);
        const promises = [];
        for (const item of combination.combo) {
          const symbol = this._cells[item.x][item.y];
          const isWild = GameConfig.WILD_ID === symbol.id;
          const promise = new Promise(resolveSymbol => {
            symbol.on('winAnimationComplete', resolveSymbol);
          });
          promises.push(promise);
          let shouldStay;

          if (isWild) {
            for (let j = i + 1; j < EntryPoint.GameModel.combinations.length; j++) {
              if (shouldStay) break;
              shouldStay = !!EntryPoint.GameModel.combinations[j].combo.filter(el => el.x === item.x && el.y === item.y).length;
            }
          }

          if (shouldStay) {
            symbol.playWild();
          } else {
            symbol.playWinAnimation();
            this._itemsToDestroyOnIterationEnd.push(symbol);
            gsap.killTweensOf(symbol);
            delete this._cells[item.x][item.y];
          }
        }
        this._playSoundCombination(combination.combo.length);
        await Promise.all(promises);
        resolveCombination();
      }, delay)
    })
  }

  _playSoundCombination(mathLength) {
    const simpleSymbolSoundIndex = mathLength > 8
      ? 8
      : mathLength < 5
        ? 5
        : mathLength;
    const simpleSymbolSoundType = `EST_SIMPLE_SYMBOL_DESTROY_${simpleSymbolSoundIndex}`;
    SoundManager.play(eSoundType[simpleSymbolSoundType], eSoundVolume[simpleSymbolSoundType])
  }

  _animatePrevStageFalling() {
    this._fallAnimations.length = 0;
    this._prevStageFallAnimations.length = 0;

    let delay = 0;

    const duration = EntryPoint.GameSettings.quickSpin ? 0.25 : 0.5;
    const delayStep = EntryPoint.GameSettings.quickSpin ? 0 : 0.03;

    for (let i = 0; i < this._cells.length; i++) {
      const column = this._cells[i];

      for (let j = column.length - 1; j >= 0; j--) {
        const item = column[j];
        this._container.addChild(item);
        delete column[j];
        const animation = gsap.to(item, {
          y: item.y + this._offsetY,
          duration,
          ease: 'power1.in',
          onComplete: () => {
            gsap.killTweensOf(item);
            item.destroy({ children: true });
          }
        }).delay(delay);
        this._prevStageFallAnimations.push(animation);
        delay += delayStep;
      }
      delay = 0.04 * (i + 1);
    }
  }

  async _animateFallingInEmptySpaces() {
    const duration = 0.5;
    const delayStep = EntryPoint.GameSettings.quickSpin ? 0 : 0.01;
    let delay = 0;
    let soundPlayed = false;
    const grid = EntryPoint.GameModel.grid;

    let timeline = gsap.timeline({ repeat: 0 });

    for (let i = 0; i < this._cells.length; i++) {

      this._cells[i] = this._cells[i].filter((item) => item !== null);

      while (this._cells[i].length < GameConfig.LINES_COUNT) {
        this._cells[i].unshift(null);
      }

      for (let j = this._cells[i].length - 1, k = GameConfig.LINES_COUNT - 1; j >= 0; j--, k--) {
        const item = this._cells[i][j];
        const y = this._coordsY[k];
        let animation;
        if (!item) {
          const id = grid[i][j].id;
          animation = this._createSymbolAndAnimateFallingFromUpside(i, j, id, delay, duration, !soundPlayed);
          soundPlayed = true;
        } else {
          if (item.y === y) continue;
          animation = this._animateFallingItem(item, { x: item.x, y: item.y }, {
            x: item.x,
            y
          }, duration, delay, null, !soundPlayed);
          soundPlayed = true;
        }
        timeline.add(animation, `falling`);
        delay += delayStep;
        this._fallAnimations.push(animation);
      }
    }

    await timeline;
    timeline.kill();
    timeline = null;
  }

  _createSymbolAndAnimateFallingFromUpside(i, j, id, delay, duration, playSound) {
    const item = FlashLib.createItemFromLibrary(eLibraryItems.ELI_FRUIT, 'GameFlashLib');
    const x = this._coordsX[i];
    const y = this._coordsY[j];
    item.x = x;
    item.y = y - this._offsetY;
    item.changeSymbol(id);

    this._cells[i][j] = item;
    this._container.addChildAt(item, 0);
    const callback = id === 1 ? () => this._topContainer.addChild(item) : null;

    return this._animateFallingItem(item, { x: item.x, y: item.y }, {
      x: item.x,
      y
    }, duration, delay, callback, playSound);
  }

  _animateFallingItem(item, from, to, duration, delay, callback, playSound) {
    const timeline = gsap.timeline({ repeat: 0, delay });

    timeline.to(item, {
      ease: 'expo',
      x: to.x,
      y: to.y + 8,
      duration: duration / 10 * 5,
      onComplete: () => playSound && SoundManager.play(eSoundType.EST_SYMBOL_STOP, eSoundVolume[eSoundType.EST_SYMBOL_STOP], false),
    }).to(item, {
      ease: 'circ',
      y: to.y - 5,
      duration: duration / 10 * 3,
    }).to(item, {
      ease: 'circ',
      y: to.y,
      duration: duration / 10 * 2,
    }).then(() => callback && callback());

    return timeline;
  }

  destroy() {
    super.destroy();

    this._cells.length = 0;
    this._fallAnimations.length = 0;
    this._prevStageFallAnimations.length = 0;

    GlobalDispatcher.remove(eEventTypes.EET_STOP_ROLLING_STATE_START, this.onNextSpinStage, this);
    GlobalDispatcher.remove(eEventTypes.EET_SPIN_NEXT_STAGE, this.onNextSpinStage, this);
    GlobalDispatcher.remove(eEventTypes.EET_ROLLING_STATE_START, this.start, this);
    GlobalDispatcher.remove(eEventTypes.EET_FIELD_STOPPED, this.onFieldStopped, this);
    GlobalDispatcher.remove(eEventTypes.EET_BOMB_ACTION, this.onBombAction, this);
    GlobalDispatcher.remove(eEventTypes.EET_FIELD_IDLE, this.playIdleAnimationForRandomSymbolsType, this);
    GlobalDispatcher.remove(eEventTypes.EET_COLOR_BOMB_RAY_END, this.onColorBombRayEnd, this);

    console.log('Controller field destroyed');
  }
}
