import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eSoundVolume from '../../sounds/eSoundVolume';
import uiConfig from '../ui/config';
import UiAdapter from './UiAdapterExtended';

const initWrapperUi = () => {
  SoundManager.play(eSoundType.EST_SFX, eSoundVolume[eSoundType.EST_SFX], true, 'music');
  window.OPWrapperService.freeBetsController.show();
  const ControllerUi = window.OPWrapperService.initUi(uiConfig);
  new UiAdapter(ControllerUi).init({
    bonusLabelText: 'FREESPINS',
    clickSound: { soundName: eSoundType.EST_BUTTON_CLICK, volume: 0.7, group: 'default' },
  });
}

export {
  initWrapperUi,
}
