import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerBackground extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    if (!EntryPoint.mobile) this.init();
  }

  init() {
    this.animationPlace = this.getChildByName('animationPlace');
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_BACKGROUND);
    this.animation.state.setAnimation(0, 'bg_animation', true);
    this.animationPlace.addChild(this.animation);
  }

  goToFrame(index) {
    super.goToFrame(index);
    if (!EntryPoint.mobile) {
      const background = this.getChildByName('background');
      background.visible = false;
    } else {
      const animationBackground = this.getChildByName('animationBackground');
      if (animationBackground) animationBackground.visible = false;
    }
  }
}
