import eServerActionTypes from '../enums/eServerActionTypes';

export default new class XXXDataParser {
  constructor() {}

  parseInit(data) {
    let newData = {};
    newData.action = eServerActionTypes.ESAT_INIT;
    newData.balance = data.balance;

    newData.freeSpins = data.freespins;
    newData.isFreeSpins = data.is_freespins;
    newData.freeSpinsMultipliers = data.freespins_multipliers;
    newData.bombFeature = data.bomb_feature;
    newData.bombFeatureTimeout = data.bomb_feature_timeout;
    newData.showTntTip = data.show_bomb_hint;
    newData.showFreeSpinsTip = data.show_freespins_hint;

    newData.params = {
      linesPossible: [10],
      betPossible: data.available_bets
    };
    newData.current = {
      betIndex: data.bet_index,
    };
    newData.spin = {
      // reels: this.slotGridToReels(data.slot_grid),
      winnings: null,
      // stopIndexes: this.getStopIndexes(data.slot_grid)
    };
    newData.platformData = {
      historyUrl: data.history_url,
      lobbyUrl: data.lobby_url
    };

    newData.currencyInfo = {
      decimals: data.decimals || 2,
      symbol: data.currency || 'USD',
      currency: data.currency || 'USD'
    };

    newData.country = data.country;

    newData.bonusCostMultiplier = data.bonus_buy_multiplier;

    newData.paytableInfo = data.paytable;

    if (data.hasOwnProperty('freebets')) newData.freebets = data.freebets;

    return newData;
  }

  parseSpin(data) {
    let newData = {};
    newData.action = eServerActionTypes.ESAT_SPIN;
    newData.balance = data.balance;
    newData.iterations = data.iterations;
    newData.colorBombs = data.color_bombs;
    newData.freeSpins = data.freespins;
    newData.isFreeSpins = data.is_freespins;
    newData.freeSpinsProgress = data.freespins_progress_current;
    newData.bombFeature = data.bomb_feature;
    newData.showTntTip = data.show_bomb_hint;
    newData.showFreeSpinsTip = data.show_freespins_hint;
    newData.totalWin = data.total_win;
    newData.totalWinWithoutMultiplier = data.total_win_without_multiplier;
    newData.betIndex = data.bet_index;
    if (data.hasOwnProperty('freebets')) newData.freebets = data.freebets
    return newData;
  }

  parsePurchase(data) {
    let newData = {};
    newData.action = eServerActionTypes.ESAT_BUY;
    newData.balance = data.balance;
    if (data.hasOwnProperty('freespins')) newData.freeSpins = data.freespins;
    if (data.hasOwnProperty('is_freespins')) newData.isFreeSpins = data.is_freespins;
    if (data.hasOwnProperty('bomb_feature')) newData.bombFeature = data.bomb_feature;
    if (data.hasOwnProperty('show_bomb_hint')) newData.showTntTip = data.show_bomb_hint;
    if (data.hasOwnProperty('show_freespins_hint')) newData.showFreeSpinsTip = data.show_freespins_hint;
    if (data.hasOwnProperty('freebets')) newData.freebets = data.freebets
    return newData;
  }

  parseFeature(data) {
    const newData = this.parseSpin(data);
    newData.action = eServerActionTypes.ESAT_USE_BOMB;
    return newData;
  }
}
