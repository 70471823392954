import { AbstractSceneController } from '../_entities/AbstractSceneController';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import { ShockwaveFilter } from '@pixi/filter-shockwave';
import { gsap } from 'gsap';
import gameConfig from '../../configs/gameConfig';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerMain extends AbstractSceneController {
  constructor(data, displayData) {
    super(data, displayData);
    this._currentFrame = 1;
  }

  init() {
    this.controllerBomb = this.getChildByName('ControllerBomb');
    this.controllerField = this.getChildByName('ControllerField');
    this.controllerUi = this.getChildByName('ControllerUi');
    this.controllerProgress = this.getChildByName('ControllerProgress');
    this.controllerLogo = this.getChildByName('ControllerLogo');
    this.controllerBackground = this.getChildByName('ControllerBackground');
    this.controllerPaytable = this.getChildByName('ControllerPaytable');
    this.controllerStartScreen = this.getChildByName('ControllerStartScreen');

    this.controllerUi.pivot.x = this.controllerUi.width / 2;
    this.controllerUi.pivot.y = this.controllerUi.height / 2;
    this.controllerUi.x += this.controllerUi.width / 2;
    this.controllerUi.y += this.controllerUi.height / 2;

    this.waveFilter = new ShockwaveFilter();
    this.waveFilter.radius = 5000;
    this.waveFilter.amplitude = 15;
    this.waveFilter.speed = 1000;
    this.waveFilter.time = 0;

    //prerender hack
    this.filters = [this.waveFilter];
    setTimeout(() => this.filters = [], 1);

    GlobalDispatcher.dispatch(eEventTypes.EET_FIELD_DISPLAY_OBJECT, this.controllerField);
  }

  addListeners() {
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    GlobalDispatcher.add(eEventTypes.EET_FIELD_DISPLAY_OBJECT, this._updateWaveCoordinates.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_BOMB_ACTION, this._onBombAction, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._onResize,
      this
    );
  }

  _onResize(data) {
    this._drawMask(data);

    const frame = data.orientation === 'V' ? 1 : 2;
    if (this._currentFrame === frame) return;
    this._currentFrame = frame;

    if (data.isMobile && data.orientation === 'V') {
      this.controllerField.scale.set(1.02);
      this.controllerField.x = this.controllerField.displayData.x;
      this.controllerField.y = this.controllerField.displayData.y;
      this.controllerBomb.portraitWrapper.x = this.controllerBomb.portraitWrapper.displayData.x;
      this.controllerBomb.portraitWrapper.y = this.controllerBomb.portraitWrapper.displayData.y;
      this.controllerBomb.portraitWrapper.scale.set(0.8);
    } else {
      this.controllerField.scale.set(1);
      this.controllerField.x = 732;
      this.controllerField.y = 10;
      this.controllerBomb.portraitWrapper.x = 1;
      this.controllerBomb.portraitWrapper.y = -105.5;
      this.controllerBomb.portraitWrapper.scale.set(1);
      window.controllerField = this.controllerField;
    }

    GlobalDispatcher.dispatch(eEventTypes.EET_FIELD_DISPLAY_OBJECT, this.controllerField);

    this.controllerProgress.changeFrame(this._currentFrame);
    this.controllerPaytable.changeFrame(this._currentFrame);
    this.controllerStartScreen.changeFrame(this._currentFrame);
    this.controllerLogo.goToFrame(this._currentFrame);
    this.controllerBackground.goToFrame(this._currentFrame);
  }

  _drawMask(data) {
    const x = data.isMobile && data.isPortrait ? (data.gameHeight - data.gameWidth) / 2 : 0;
    const y = data.isMobile && data.isPortrait ? -(data.gameHeight - data.gameWidth) / 2 : 0;

    if (!this.gameMask) {
      this.gameMask = new PIXI.Graphics();
    } else {
      this.gameMask.clear();
    }
    this.gameMask.beginFill(0xFF3300);
    this.gameMask.drawRect(
      x,
      y,
      data.gameWidth,
      data.gameHeight
    );
    this.gameMask.endFill();
    this.addChild(this.gameMask);
    this.mask = this.gameMask;
  }

  _onBombAction() {
    this._shake();
    if (EntryPoint.mobile) return;
    this._startWave();
  }

  _startWave() {
    this.filters = [this.waveFilter];
    gsap.to(this.waveFilter, { time: 1, duration: 1, onComplete: this._resetWave.bind(this) });
  }

  _shake() {
    const timeline = gsap.timeline({ repeat: 0 });
    timeline.to(this.controllerField.scale,
      {
        y: this.controllerField.scale.y * 0.96,
        x: this.controllerField.scale.x * 0.96,
        duration: 0.05,
        ease: 'none'
      }, 'startShake').to(this.controllerField.scale,
      {
        y: this.controllerField.scale.y,
        x: this.controllerField.scale.x,
        duration: 0.8,
        ease: 'elastic.out(1, 0.1)'
      }, 'shake');

    timeline.to(this.controllerUi.scale,
      {
        y: this.controllerUi.scale.y * 0.96,
        x: this.controllerUi.scale.x * 0.96,
        duration: 0.05,
        ease: 'none'
      }, 'startShake').to(this.controllerUi.scale,
      {
        y: this.controllerUi.scale.y,
        x: this.controllerUi.scale.x,
        duration: 0.8,
        ease: 'elastic.out(1, 0.1)'
      }, 'shake');
  }

  _resetWave() {
    this.filters = [];
    this.waveFilter.time = 0;
  }

  _updateWaveCoordinates(data) {
    const field = data.params;
    const fieldPositionToLocal = this.toLocal({ x: 0, y: 0 }, field);

    this.waveFilter.center = [
      fieldPositionToLocal.x + gameConfig.COLUMNS_COUNT * (gameConfig.SYMBOLS.width + gameConfig.SYMBOLS.sideOffset) * field.scale.x / 2 - ((2340 - window.innerWidth) / 2),
      fieldPositionToLocal.y + gameConfig.LINES_COUNT * (gameConfig.SYMBOLS.height + gameConfig.SYMBOLS.topOffset) * field.scale.y / 2,
    ];
  }

  sceneDidAppear() {
    super.sceneDidAppear();
    console.log('ControllerMain did appear');
  }

  onStateChanged({ params }) {
    switch (params) {
      case 'idleState':
        break;
      case 'requestRollingState':
        break;
    }
  }
}
