import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';

export default class ControllerFruit extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._animationRelations = {
      1: 'coctail',
      2: 'carambole',
      3: 'orange',
      4: 'grape',
      5: 'strawberry',
      6: 'blueberry',
      7: 'apple'
    };

    this.init();

    this._onCompleteAnimation = this._onCompleteAnimation.bind(this);
    this.playWinAnimation = this.playWinAnimation.bind(this);
  }

  init() {
    this._animationPlace = this.getChildByName('animationPlace');
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_FRUITS);
    this._animationPlace.addChild(this.animation);
  }

  changeSymbol(id) {
    this._id = id;
    this.playIdleAnimation();
  }

  playWinAnimation() {
    this.animation.state.clearListeners();
    this.animation.state.setAnimation(0, this._animationRelations[this._id], false);
    this.animation.state.timeScale = 1;
    this.animation.state.addListener({ complete: this._onCompleteAnimation });
  }

  playWild() {
    this.animation.state.setAnimation(0, `${this._animationRelations[this._id]}_wild`, false);
    this.animation.state.timeScale = 1;
    this.animation.state.addListener({ complete: this._onCompleteAnimation });
  }

  _onCompleteAnimation() {
    this.animation.state.clearListeners();
    this.emit('winAnimationComplete');
    this.removeAllListeners();
  }

  playIdleAnimation() {
    this.animation.state.timeScale = 0.5;
    this.animation.state.setAnimation(0, `${this._animationRelations[this._id]}_idle`, false);
  }

  get id() {
    return this._id;
  }
}
