import './imports';
import '../../assets/scss/styles.scss';
import 'Engine/utils/spineExtantion'
import { gsap } from 'gsap';
import { MotionPathPlugin } from './utils/gsap/MotionPathPlugin.min';
import { StatesConfig } from './states/StatesConfig';
import scaleConfig from './configs/scaleConfig';
import preloaderConfig from './configs/preloaderConfig';
import GameModel from './models/gameModel';
import AutoPlaySettings from 'Engine/base/settings/AutoPlaySettings';
import PreloaderController from './libs/game-engine/src/base/preloader/BasePreloaderController';
import PreloaderView from './libs/op-preloader/preloader';
import StatesManager from 'Engine/base/states/StatesManager';
import FlashLib from 'flashlib_onlyplay';
import ControllerTextField from './controllers/common/textField';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import wrapperConfig from './configs/wrapperConfig';
import OPWrapperService from './libs/op-wrapper-service';
import BaseGameSettings from 'Engine/base/settings/BaseGameSettings';
import eLibraryItems from './enums/eLibraryItems';

import preloaderBackground from '../../assets/images/preloader_background.webp';

new OPWrapperService(wrapperConfig);
gsap.registerPlugin(MotionPathPlugin);

export default class Game {
  constructor() {

    this.gameSize = {
      desktop: {
        width: 2340,
        height: 1080,
      },
      mobileLandscape: {
        width: 2340,
        height: 1080,
      },
      mobilePortrait: {
        width: 1080,
        height: 2340,
      },
    };

    this.start();
  }

  start() {
    this.createGame();
    this.initClasses();
    this.addListeners();
  }

  initClasses() {
    GameModel.init();
    const autoPlaySettings = new AutoPlaySettings();
    const settings = new BaseGameSettings();
    autoPlaySettings.init();
    settings.init();
    StatesManager.setStatesConfig(StatesConfig);
    FlashLib.TextField = ControllerTextField;
    this.createPreloader();
  }

  addListeners() {
    GlobalDispatcher.add('preloaderController:progress', this.onPreloaderProgress, this);
  }

  createPreloader() {
    this.preloaderController = new PreloaderController(preloaderConfig);
    this.preloaderController.on('gameLoadingComplete', this.onLoadingComplete, this);

    this.preloaderView = document.getElementById('preloader');
    this.preloaderView.style.background = `url(${preloaderBackground}) no-repeat center`;
    this.preloaderView.style.backgroundSize = `cover`;
  }

  onLoadingComplete() {
    this.initGame();
  }

  onPreloaderProgress(event) {
    PreloaderView.setProgress(event.params);
  }

  hidePreloader() {
    gsap.to(this.preloaderView, {
      duration: 0.5,
      alpha: 0, ease: 'none', onComplete: () => {
        PreloaderView.hide();
      }
    });
  }

  createGame() {
    this.applicationOptions = {
      backgroundColor: 0x0,
      resolution: window.devicePixelRatio < 2 ? window.devicePixelRatio : 2,
    };
    this.app = new PIXI.Application(this.applicationOptions);
    // window.PixiApp = this.app;
    this.app.view.id = 'gameCanvas';
    this.container = document.getElementById('container');
    this.container.appendChild(this.app.view);
  }

  createScene() {
    const scene = FlashLib.createItemFromLibrary(eLibraryItems.ELI_MAIN, 'GameFlashLib');
    this.app.stage.addChildAt(scene, 0);
    return scene;
  }

  initGame() {
    const scene = this.createScene();
    window.OPWrapperService.ScaleManager.init({ app: this.app, gameSize: this.gameSize }, scaleConfig);

    setTimeout(() => {
      this.hidePreloader();
      scene.sceneDidAppear();
    }, 1000);
    GlobalDispatcher.dispatch('game:gameStarted');
  }
}
