import * as PIXI from 'pixi.js';
import { getBonusPurchaseCardsConfig } from '../../enums/eBonusPurchaseCards';
import FlashLib from 'flashlib_onlyplay';
import GameModel from '../../models/gameModel';
import animationCreator from 'Engine/animations/animationCreator';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eEventTypes from '../../enums/eEventTypes';

export default class ControllerBonusPurchase extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;

    this.init();
    this.initCards();
    this.initTitleAnimation();
    this.addListeners();
    this.onResize();
    this.hide();
  }

  init() {
    this.confirmModal = this.getChildByName('confirmModal');
    this.btnClose = this.getChildByName('btnClose');
    makeHitArea(this.btnClose);
    this.btnClose.enabled = true;
    this.btnClose.displayData.x = 1900;
    this.btnClose.displayData.y = 100;
    this.btnClose.x = this.btnClose.displayData.x;
    this.btnClose.y = this.btnClose.displayData.y;
    this.animationContainer = this.getChildByName('animationContainer');
    this.description = this.getChildByName('purchase_description');

    this.betSelector = this.getChildByName('cashBet');
    this.betSelector.setRange(EntryPoint.GameModel.betPossible);
    this.betSelector.setValue(EntryPoint.GameModel.bet);
    this.betSelector.enableButtons();
    const betSelectorLabel = this.betSelector.getChildByName('purchase_bet_label');
    betSelectorLabel.style = {...betSelectorLabel.style};
  }

  initCards() {
    this.cards = [];
    const bonusCardsDataArray = Object.values(getBonusPurchaseCardsConfig());
    for (let i = 0; i < bonusCardsDataArray.length; i++) {
      const card = this.getChildByName(`card_${i}`);
      card.data = bonusCardsDataArray[i];
      card.updatePrice();
      this.cards.push(card);
    }
  }

  initTitleAnimation() {
    this.titleAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_PURCHASE);
    if (!this.titleAnimation) return;

    this.titleAnimation.state.setAnimation(0, 'title_start', false);
    this.titleAnimation.state.addAnimation(0, 'title_idle', true, 0);
    this.animationContainer.addChild(this.titleAnimation);
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_PURCHASE_BTN_CLICK, this.show.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_BET_CHANGED, this.onBetChanged, this);
    GlobalDispatcher.add(eEventTypes.EET_SPIN_CLICK, this.hide.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_BUY_BONUS, this.hide.bind(this));
    window.addEventListener('resize', () => setTimeout(this.onResize.bind(this), 1));
    this.btnClose.addListener('pointertap', this.hide.bind(this));
  }

  onResize() {
    const width = Math.min(window.innerWidth, window.outerWidth);
    const height = Math.min(window.innerHeight, window.outerHeight);
    const isHorizontalOrientation = (width - height) > 0;

    if (PIXI.utils.isMobile.any && isHorizontalOrientation) {

      this.animationContainer.y = this.animationContainer.displayData.y;

      this.btnClose.x = this.btnClose.displayData.x;
      this.btnClose.y = this.btnClose.displayData.y;

      this.betSelector.y = this.betSelector.displayData.y;

    } else if (PIXI.utils.isMobile.any) {
      // const viewWidthGame = gameConfig.GAME_HEIGHT * width / height;
      // const scaleGame = viewWidthGame / gameConfig.GAME_WIDTH;

      this.animationContainer.y = -50;

      this.btnClose.x = 1500;
      this.btnClose.y = -296;

      this.betSelector.y = this.betSelector.displayData.y + 250;
    }

  }

  show() {
    this.cards.forEach(card => card.show());
    this.initTitleAnimation();
    this.onResize();
    this.visible = true;
  }

  hide() {
    if (!this.visible) return;

    if (this.confirmModal.visible) {
      this.confirmModal.hide();
    }

    this.cards.forEach(card => card.beforeUnload());
    this.titleAnimation.destroy({ children: true });
    this.visible = false;

    GlobalDispatcher.dispatch('ui:closePurchase');
  }

  onBetChanged(event) {
    this.betSelector.setValue(event.params);
    this.cards.forEach(card => card.updatePrice())
  }
}
