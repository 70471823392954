export const pinkStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: "#ac090a",
  dropShadowDistance: 12,
  fill: [
    "#ff388c",
    "#f66",
    "#ff097d",
    "#f60aff"
  ],
  fillGradientStops: [
    0.2,
    0.4,
    0.6,
    0.7
  ],
  letterSpacing: 3,
  lineJoin: "round",
  miterLimit: 0,
  stroke: "white",
  strokeThickness: 3
};

export const goldStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: "#ac090a",
  dropShadowDistance: 12,
  fill: [
    "#ffff6e",
    "#ffff9e",
    "#ffb209",
    "#ff780a"
  ],
  fillGradientStops: [
    0.2,
    0.4,
    0.6,
    0.7
  ],
  lineJoin: "round",
  miterLimit: 0,
  stroke: "#ffde05",
  strokeThickness: 3
};

export const yellowStyle = {
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: "#51020a",
  dropShadowDistance: 12,
  fill: [
    "#ff6d00",
    "yellow",
    "white",
    "yellow",
    "#ff6d00"
  ],
  fillGradientStops: [
    0.2,
    0.4,
    0.5,
    0.6,
    0.9
  ],
  letterSpacing: 0,
  lineJoin: "round",
  miterLimit: 0,
  stroke: "#d6040d",
  strokeThickness: 6
};

export const blueStrokeStyle = {
  lineJoin: "round",
  miterLimit: 0,
  stroke: "#201180",
  strokeThickness: 6
};

export const lightBlueStrokeStyle = {
  lineJoin: "round",
  miterLimit: 0,
  stroke: "#01aac9",
  strokeThickness: 12,
  fill: 'white'
};

export const whiteYellowOrange = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};

export const shadowText = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowBlur: 4
};
