import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import GameModel from '../models/gameModel';
import gameConfig from '../configs/gameConfig';
import eEventTypes from '../enums/eEventTypes';

export default class RequestRollingState extends BaseState {
  constructor(stateData) {
    super(stateData);

    this.timeout = null;
    GameModel.startRequestTime = 0;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_DATA_UPDATED, this.onGetServerData, this);
  }

  onGetServerData(data) {
    if (data.params === 'spin') {
      const delay = gameConfig.MINIMAL_SPIN_TIME - (Date.now() - GameModel.startRequestTime);
      this.timeout = setTimeout(this.complete.bind(this), delay);
    }
  }

  start() {
    super.start();
    GameModel.startRequestTime = Date.now();
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    if (!EntryPoint.GameModel.isFreeSpinsMode) {
      OPWrapperService.freeBetsController.decreaseFreeBets();
      OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bet;
    }
  }

  onEnd() {
    super.onEnd();
    clearTimeout(this.timeout);
    EntryPoint.GameSettings.quickStop = false;
  }
}
