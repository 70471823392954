import GameModel from '../models/gameModel';
import InitState from './InitState';
import IdleState from './IdleState';
import RequestRollingState from './RequestRollingState';
import StopRollingState from './StopRollingState';
import ShowBigWinState from './ShowBigWinState';
import FreeSpinsAddState from './FreeSpinsAddState';
import FreeSpinsInState from './FreeSpinsInState';
import FreeSpinsOutState from './FreeSpinsOutState';
import ColorBombActionState from './ColorBombActionState';
import ColorBombIterationState from './ColorBombIterationState';
import BombFeatureState from './BombFeatureState';
import BuyBonusState from './BuyBonusState';
import WaitUserInteractionState from './WaitUserInteractionState';
import StartScreenState from 'Engine/base/states/BaseState';
import ShowFreeSpinsMultiplierState from './ShowFreeSpinsMultiplierState';
import eStateTypes from '../enums/eStateTypes';
import EntryPoint from 'Engine/EntryPoint';

export const StatesConfig = [
  {
    name: eStateTypes.EST_INIT,
    class: InitState,
    transitions: [
      {
        to: eStateTypes.EST_START_SCREEN, condition: function () {
          return EntryPoint.GameSettings.introScreen;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_WAIT_USER_INTERACTION, condition: function () {
          return GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {
          return GameModel.isFreeSpinsFinished;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ],
    entry: true
  },
  {
    name: eStateTypes.EST_IDLE,
    class: IdleState,
    transitions: [
      {
        to: eStateTypes.EST_REQUEST_ROLLING, condition: function () {
          return GameModel.isFreeSpinsMode || GameModel.checkStartSpin();
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_START_SCREEN,
    class: StartScreenState,
    transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_WAIT_USER_INTERACTION, condition: function () {
          return GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_REQUEST_ROLLING,
    class: RequestRollingState,
    transitions: [
      {
        to: eStateTypes.EST_STOP_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_STOP_ROLLING,
    class: StopRollingState,
    transitions: [
      {
        to: eStateTypes.EST_COLOR_BOMB_ACTION, condition: function () {
          return GameModel.useColorBomb;
        }
      },
      {
        to: eStateTypes.EST_SHOW_FREE_SPINS_MULTIPLIER_WIN, condition: function () {
          return GameModel.isFreeSpinsMultiplierWin;
        }
      },
      {
        to: eStateTypes.EST_SHOW_BIG_WIN, condition: function () {
          return GameModel.isBigWin;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {
          return GameModel.isFreeSpinsFinished;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_BIG_WIN,
    class: ShowBigWinState,
    transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {
          return GameModel.isFreeSpinsFinished;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_FREE_SPINS_MULTIPLIER_WIN,
    class: ShowFreeSpinsMultiplierState,
    transitions: [
      {
        to: eStateTypes.EST_SHOW_BIG_WIN, condition: function () {
          return GameModel.isBigWin;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return GameModel.isFreeSpinsMode;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_OUT, condition: function () {
          return GameModel.isFreeSpinsFinished;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FREE_SPINS_IN,
    class: FreeSpinsInState, transitions: [
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FREE_SPINS_ADD,
    class: FreeSpinsAddState,
    transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_FREE_SPINS_OUT,
    class: FreeSpinsOutState,
    transitions: [
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_COLOR_BOMB_ACTION,
    class: ColorBombActionState,
    transitions: [
      {
        to: eStateTypes.EST_COLOR_BOMB_ITERATION, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_COLOR_BOMB_ITERATION,
    class: ColorBombIterationState,
    transitions: [
      {
        to: eStateTypes.EST_COLOR_BOMB_ACTION, condition: function () {
          return GameModel.useColorBomb;
        }
      },
      {
        to: eStateTypes.EST_SHOW_BIG_WIN, condition: function () {
          return GameModel.isBigWin;
        }
      },
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EST_BOMB_FEATURE,
    class: BombFeatureState,
    transitions: [
      {
        to: eStateTypes.EST_STOP_ROLLING, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EST_BUY_BONUS,
    class: BuyBonusState,
    transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_ADD, condition: function () {
          return GameModel.isFreeSpinsStarts;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EST_WAIT_USER_INTERACTION,
    class: WaitUserInteractionState,
    transitions: [
      {
        to: eStateTypes.EST_FREE_SPINS_IN, condition: function () {
          return true;
        }
      },
    ]
  },
];
