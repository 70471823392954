import en from '../../../assets/localizations/en';
import enSocial from '../../../assets/localizations/en-social';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import eSoundTypes from '../sounds/eSoundType';

const onSettingsClick = () => {
  SoundManager.play(eSoundTypes.EST_BUTTON_CLICK, 0.7, false, 'default');
  GlobalDispatcher.dispatch(eEventTypes.EET_SETTINGS_CLICK);
};

export default {
  buttons: [
    {
      type: 'settings',
      onClick: onSettingsClick,
      styles: ['settings']
    }
  ],
  bundle: 'juicycrush',
  localizationBundle: 'juicycrush',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
