import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import { initWrapperUi } from '../controllers/ui/initWrapperUi';

export default class InitState extends BaseState {
    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('game:gameStarted', this.onGameStarted, this);
    }

    onGameStarted() {
        if (!EntryPoint.GameSettings.introScreen) initWrapperUi();
        if (EntryPoint.GameModel.isFreeSpinsMode) window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
        this.complete();
    }
}
