import FlashLib from "flashlib_onlyplay";

export default class ControllerOverlay extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._alphaValue = 0.85;
    this.interactive = true;

    this.init();
    this.addListeners();
  }

  init() {
    this.getChildAt(0).alpha = this.alphaValue;
  }

  addListeners() {

  }

  get alphaValue() {
    return this._alphaValue;
  }

  set alphaValue(value) {
    this._alphaValue = value;
    this.getChildAt(0).alpha = this.alphaValue;
  }
}
