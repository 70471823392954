export default {
  EST_BUTTON_CLICK: 'buttonClick',
  EST_SFX: 'SFX',
  EST_SYMBOL_STOP: 'symbolStop',
  EST_BIG_WIN_BACKGROUND: 'bigWinBg',
  EST_BIG_WIN: 'bigWin',
  EST_MEGA_WIN: 'megaWin',
  EST_EPIC_WIN: 'epicWin',
  EST_COLOR_BOMB_RAY: 'colorBombRaySound',
  EST_COLOR_BOMB_COLLAPSE: 'colorBombCollapse',
  EST_FIELD_BOMB: 'fieldBomb',
  EST_WIN_FREESPINS: 'winFreespins',
  EST_FREESPINS_TOTAL_WIN: 'freespinsTotalWin',
  EST_INCREASE_PROGRESS: 'increaseProgress',
  EST_SIMPLE_SYMBOL_DESTROY_5: 'simpleSymbolDestroy_5',
  EST_SIMPLE_SYMBOL_DESTROY_6: 'simpleSymbolDestroy_6',
  EST_SIMPLE_SYMBOL_DESTROY_7: 'simpleSymbolDestroy_7',
  EST_SIMPLE_SYMBOL_DESTROY_8: 'simpleSymbolDestroy_8',
}
