import SlotGameModel from 'Engine/slots/model/SlotGameModel';
import gameConfig from '../configs/gameConfig';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import ServerManager from '../api/ServerManager';
import { shuffle } from '../utils/shuffle';
import StatesManager from 'Engine/base/states/StatesManager';
import eStateTypes from '../enums/eStateTypes';
import { getBonusPurchaseCardsConfig } from '../enums/eBonusPurchaseCards';
import { getUrlParam } from '../libs/op-wrapper-service/utils/url';

export default new class GameModel extends SlotGameModel {
  constructor() {
    super();

    this._currentStage = 0;
    this.startRequestTime = 0;

    EntryPoint.GameModel = this;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add(eEventTypes.EET_SPIN_CLICK, this._nextState.bind(this));
  }

  init() {
    super.init();
    ServerManager.onInit();
  }

  setNewData(data) {
    this._lastWin = this.totalWin || this._lastWin;
    this._currencyInfo = data.currencyInfo || this._currencyInfo;

    this._isSpinWithoutBalanceCheck = false;

    if (data.hasOwnProperty('balance')) {
      this._balance = +data.balance;
    }

    if (data.hasOwnProperty('params')) {
      this._betPossible = data.params.betPossible || this._betPossible;
      this._linesPossible = data.params.linesPossible || this._linesPossible;
    }

    if (data.hasOwnProperty('current')) {
      let maxIndex = this._betPossible.filter(b => b <= this._balance).length - 1;
      if (maxIndex <= 0) maxIndex = 0;
      // pid: 217 PMBelarus
      const defaultBetIndex = getUrlParam('pid') === '217' ? 0 : Math.min(this._betPossible.length - 2, maxIndex);
      this._betIndex = data.current.betIndex >= 0 ? data.current.betIndex : defaultBetIndex;
      this._bet = this._betPossible[this._betIndex];
    }

    this._freebetsIsActive = data.hasOwnProperty('freebets') && data.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
    if (data.hasOwnProperty('freebets')) {
      this._freeBetPossible = data.freebets.bet_levels;
    }
    if (this._freebetsIsActive) {
      this.betPossible = this._freeBetPossible;
      this.betIndex = data.freebets.bet_level;
    } else {
      this.betPossible = this._betPossible;
    }
    window.OPWrapperService.freeBetsController.setData(data.freebets);

    if (data.hasOwnProperty('betIndex') && !this._freebetsIsActive) {
      this.betIndex = data.betIndex;
    }

    if (data.action === 'spin') {
      const isLastFreeBet = data.freebets && data.freebets.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_PLAYED;
      if (this._freebetsIsActive || isLastFreeBet) {
        window.OPWrapperService.ControllerStatistic.increaseFreeBetsCount();
      } else {
        window.OPWrapperService.ControllerStatistic.increaseSpinCount(this._betIndex , EntryPoint.AutoPlaySettings.active);
      }
    }

    if (data.hasOwnProperty('bonusCostMultiplier')) this.bonusCostMultiplier = data.bonusCostMultiplier;

    if (data.hasOwnProperty('iterations')) this.iterations = data.iterations;

    if (data.hasOwnProperty('colorBombs')) this.colorBombs = data.colorBombs;

    if (data.hasOwnProperty('freeSpinsProgress')) this.freeSpinsProgress = data.freeSpinsProgress;

    if (data.hasOwnProperty('bombFeature')) this.bombFeature = data.bombFeature;

    if (data.hasOwnProperty('bombFeatureTimeout')) this._bombFeatureTimeout = data.bombFeatureTimeout;

    if (data.hasOwnProperty('showTntTip')) this.showTntTip = data.showTntTip;

    if (data.hasOwnProperty('showFreeSpinsTip')) this.showFreeSpinsTip = data.showFreeSpinsTip;

    if (data.hasOwnProperty('payouts')) this.payouts = data.payouts;

    if (data.hasOwnProperty('paytableInfo')) this._paytableInfo = data.paytableInfo;

    if (data.hasOwnProperty('isFreeSpins')) {
      this._isFreeSpinsMode = data.isFreeSpins;
    }

    if (data.hasOwnProperty('freeSpins')) {
      this._freeSpins = data.freeSpins;
    }

    if (data.hasOwnProperty('freeSpinsMultipliers')) this.freeSpinsMultipliers = data.freeSpinsMultipliers;

    if (data.hasOwnProperty('totalWin')) {
      this._totalWin = data.totalWin;
      window.OPWrapperService.ControllerStatistic.win = this.totalWin;
    } else {
      this._totalWin = 0;
    }

    if (data.hasOwnProperty('totalWinWithoutMultiplier')) {
      this._totalWinWithoutMultiplier = data.totalWinWithoutMultiplier;
    } else {
      this._totalWinWithoutMultiplier = 0;
    }

    if (data.hasOwnProperty('platformData')) {
      this._platformData = data.platformData || this.platformData;
    }

    this._currentStage = 0;

    this._addPropertyIsLastWinToLastWinCombination();

    GlobalDispatcher.dispatch(eEventTypes.EET_DATA_UPDATED, data.action);
  }

  _addPropertyIsLastWinToLastWinCombination() {
    if (!this.iterations || !this.colorBombs) return;
    const colorBombsIterations = this.colorBombs.reduce((prev, bombData)=> {
      prev.push(...bombData.iterations);
      return prev;
    }, [])
    const allIteration = [...this.iterations, ...colorBombsIterations]
    allIteration.reverse();
    const lastWinIteration = allIteration.find(iteration => iteration.combinations.length)
    if (!lastWinIteration) return;
    const lastWinCombination = lastWinIteration.combinations[lastWinIteration.combinations.length - 1];
    lastWinCombination.isLastWin = true;
  }

  nextStage() {
    this._currentStage++;
    if (this._currentStage < this.iterations.length) {
      GlobalDispatcher.dispatch(eEventTypes.EET_SPIN_NEXT_STAGE);
    } else {
      this._currentStage = 0;
      GlobalDispatcher.dispatch(eEventTypes.EET_FIELD_STOPPED);
    }
  }

  bombStage() {
    if (this.colorBombs.length) {
      const colorBomb = this.colorBombs.shift();
      this.iterations = colorBomb.iterations;
      GlobalDispatcher.dispatch(eEventTypes.EET_SPIN_NEXT_STAGE);
    } else {
      GlobalDispatcher.dispatch(eEventTypes.EET_FIELD_STOPPED);
    }
  }

  useBomb() {
    StatesManager.goToState(eStateTypes.EST_BOMB_FEATURE);
  }

  buyBonus({ params }) {
    if (params.price > this.balance) {
      window.OPWrapperService.showError(window.OPWrapperService.errors.INSUFFICIENT_BALANCE_CLIENT.CODE);
      return;
    }
    this.bonusPurchaseData = params;
    StatesManager.goToState(eStateTypes.EST_BUY_BONUS);
  }

  checkStartSpin() {
    return true;
  }

  getBonusPurchasePrice(type) {
    return this.bet * this.bonusCostMultiplier[type];
  }

  isPurchaseCardAvailable(type) {
    switch (type) {
      case getBonusPurchaseCardsConfig().EBPT_TNT_BOOSTER.type:
        return this.currentBomb.progress !== 100;
      case getBonusPurchaseCardsConfig().EBPT_FREE_SPINS.type:
        return true;
      default:
        return false;
    }
  }

  isLastCombination(combination) {
    const index = this.combinations.indexOf(combination);
    return index >= 0 && index === this.combinations.length - 1;
  };

  _nextState() {
    StatesManager.goToNextState();
  }

  set totalWin(value) {
    this._totalWin = value;
  }

  set spinEndTimestamp(value) {
    this._spinEndTimestamp = value;
  }

  set cells(value) {
    this._fieldCells = value;
  }

  set isFreeSpinsMode(value) {
    this._isFreeSpinsMode = value;
  }

  set isFreeSpinsInActiveState(value) {
    this._isFreeSpinsInActiveState = value;
  }

  set bonusPurchaseData(value) {
    this._bonusPurchaseData = value;
  }

  get currentBomb() {
    return this.bombFeature[this.betIndex];
  }

  get totalWin() {
    return this._totalWin || 0;
  }

  get grid() {
    return this.iterations[this._currentStage].grid;
  }

  get combinations() {
    return this.iterations[this._currentStage].combinations;
  }

  get useColorBomb() {
    return !!this.colorBombs.length;
  }

  get cells() {
    return this._fieldCells;
  }

  get spinEndTimestamp() {
    return this._spinEndTimestamp;
  }

  get isBigWin() {
    return this.totalWin >= this.bet * gameConfig.BIG_WIN_COEFS[0];
  }

  get isFreeSpinsStarts() {
    return this._isFreeSpinsMode ? this._freeSpins.amount_given === this._freeSpins.amount_left : false;
  }

  get isFreeSpinsMode() {
    return this._isFreeSpinsMode;
  }

  get isFreeSpinsInActiveState() {
    return this._isFreeSpinsInActiveState;
  }
  get freeSpinsCount() {
    return EntryPoint.GameModel.freeSpins.amount_left;
  }

  get freespinsTotalWin() {
    return this._freeSpins.total_win || 0;
  }

  get currentFreeSpinsMultiplier() {
    return this._freeSpins.current_multiplier;
  }

  get nextFreeSpinsMultiplier() {
    return this._freeSpins.next_multiplier;
  }

  get isFreeSpinsMultiplierWin() {
    return this.isFreeSpinsInActiveState && this.currentFreeSpinsMultiplier !== this.nextFreeSpinsMultiplier
  }

  get nextAvailableFreeSpinsMultiplier() {
    const index = this.freeSpinsMultipliers.indexOf(this.nextFreeSpinsMultiplier);
    return index + 1 === this.freeSpinsMultipliers.length ? this.freeSpinsMultipliers[index] : this.freeSpinsMultipliers[index + 1];
  }

  get isFreeSpinsFinished() {
    return !this._isFreeSpinsMode && !!Object.keys(this._freeSpins).length;
  }

  get colorBombField() {
    return this.colorBombs[0].cells_to_remove;
  }

  get currentState() {
    return StatesManager.currentState.stateData.name;
  }

  get bonusPurchaseData() {
    return this._bonusPurchaseData;
  }

  get bombFeatureTimeout() {
    return this._bombFeatureTimeout;
  }

  get isBombFeatureActive() {
    return (Date.now() - this.spinEndTimestamp) / 1000 <= this.bombFeatureTimeout;
  }

  get totalWinWithoutMultiplier() {
    return this._totalWinWithoutMultiplier;
  }

  get freespinsEnabled() {
    return this.isFreeSpinsInActiveState
  }

  get freespins() {
    return this.freeSpinsCount
  }
}
