import eStateTypes from 'Engine/enums/eBaseStateTypes';

export default {
  EST_WAIT_USER_INTERACTION: 'waitUserInteractionState',
  EST_START_SCREEN: 'startScreenState',
  EST_IDLE: 'idleState',
  EST_BOMB_FEATURE: 'bombFeatureState',
  EST_BUY_BONUS: 'buyBonusState',
  EST_COLOR_BOMB_ACTION: 'colorBombActionState',
  EST_COLOR_BOMB_ITERATION: 'colorBombIterationState',
  EST_FREE_SPINS_ADD: 'FreespinsAddState',
  EST_FREE_SPINS_IN: 'FreespinsInState',
  EST_FREE_SPINS_OUT: 'FreespinsOutState',
  EST_INIT: 'initState',
  EST_REQUEST_ROLLING: 'requestRollingState',
  EST_SHOW_BIG_WIN: 'showBigWinState',
  EST_SHOW_FREE_SPINS_MULTIPLIER_WIN: 'showFreeSpinsMultiplierWinState',
  EST_STOP_ROLLING: 'stopRollingState',

  ...eStateTypes
}
