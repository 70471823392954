import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import { getRandom } from 'Engine/utils/getRandom';
import eEventTypes from '../../enums/eEventTypes';
import EntryPoint from 'Engine/EntryPoint';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import eStateTypes from '../../enums/eStateTypes';
import StatesManager from 'Engine/base/states/StatesManager';
import FlashLib from 'flashlib_onlyplay';

export default class ControllerUi extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
    this.addListeners();
    this.addActions();
  }

  addListeners() {
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }
  init() {
    this.purchaseButton = this.getChildByName('purchaseButton');
    makeHitArea(this.purchaseButton);
    this.purchaseButton.enabled = !EntryPoint.GameModel.isFreeSpinsMode;
    if (window.OPWrapperService.config.disableBuyBonus) this.removeChild(this.purchaseButton);
  }

  initPurchaseAnimation() {
    this.purchaseButtonAnimation = animationCreator.createAnimation(eAnimationTypes.EAT_PURCHASE_BUTTON);
    this.purchaseButtonAnimation.state.addListener({
      complete: this.loopPurchaseAnimation.bind(this)
    });
    this.purchaseButton.animation = this.purchaseButtonAnimation;
  }

  loopPurchaseAnimation() {
    if (!this.purchaseButtonAnimation) {
      this.initPurchaseAnimation();
    }

    this.purchaseButtonAnimation.visible = false;
    let delay = getRandom(2000, 10000);

    this.purchaseAnimationTimeout = setTimeout(() => {
      if (!this.purchaseButtonAnimation) return;

      this.purchaseButtonAnimation.visible = true;
      this.purchaseButtonAnimation.state.setAnimation(0, 'animation', false);
    }, delay);
  }

  stopPurchaseAnimation() {
    if (this.purchaseAnimationTimeout) {
      clearTimeout(this.purchaseAnimationTimeout);
      this.purchaseAnimationTimeout = null;
    }

    if (this.purchaseButtonAnimation) {
      this.purchaseButtonAnimation.destroy();
      this.purchaseButtonAnimation = null;
    }
  }
  onPurchaseClick() {
    GlobalDispatcher.dispatch(eEventTypes.EET_PURCHASE_BTN_CLICK);
  }

  addActions() {
    this.purchaseButton.on('pointertap', this.onPurchaseClick, this);
  }

  onGameResized(data, priorityFrameIndex) {
      this.stopPurchaseAnimation();
      const frameIndex = priorityFrameIndex || (data.isLandscape ? 2 : 1);
      if (frameIndex === this.currentFrameIndex) return;
      this.goToFrame(frameIndex);
      this.init();
      this.addActions();
      this.onStateChanged({ params: StatesManager.currentState.stateData.name });
  }

  onStateChanged({ params }) {
    switch (params) {
      case eStateTypes.EST_START_SCREEN:
        this.purchaseButton.enabled = true;
        this.purchaseButton.visible = true;
        break;
      case eStateTypes.EST_IDLE:
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
          this.purchaseButton.enabled = true;
          this.purchaseButton.visible = true;
          this.loopPurchaseAnimation();
        }
        break;
      case eStateTypes.EST_REQUEST_ROLLING:
      case eStateTypes.EST_STOP_ROLLING:
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;
      case eStateTypes.EST_WAIT_USER_INTERACTION:
      case eStateTypes.EST_FREE_SPINS_ADD:
      case eStateTypes.EST_FREE_SPINS_IN:
        this.stopPurchaseAnimation();
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = false;
        break;
      case eStateTypes.EST_FREE_SPINS_OUT:
        break;
      case eStateTypes.EST_SHOW_BIG_WIN:
      case eStateTypes.EST_BUY_BONUS:
      case eStateTypes.EST_BOMB_FEATURE:
      case eStateTypes.EST_COLOR_BOMB_ACTION:
      case eStateTypes.EST_COLOR_BOMB_ITERATION:
        this.stopPurchaseAnimation();
        this.purchaseButton.enabled = false;
        break;
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.stopPurchaseAnimation();
      this.purchaseButton.enabled = false;
      this.purchaseButton.visible = false;
    }
  }
}
