import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import { initWrapperUi } from '../ui/initWrapperUi';

export default class ControllerStartScreen extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.visible = EntryPoint.GameSettings.introScreen;
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_START_SCREEN_START, this.init.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_START_SCREEN_END, this._complete.bind(this));
  }

  init() {
    const desc1 = this.getChildByName('start_screen_desc1');
    const desc2 = this.getChildByName('start_screen_desc2');
    const desc5 = this.getChildByName('start_screen_desc5');
    const desc6 = this.getChildByName('start_screen_desc6');
    const desc7 = this.getChildByName('start_screen_desc7');
    [desc1, desc2, desc5, desc6, desc7].forEach(el => this._addStroke(el));
    const localizationManager = window.OPWrapperService.localizations
    desc2.text = `${localizationManager.getLocalizedText('start_screen_desc2')} ${localizationManager.getLocalizedText('start_screen_desc3')} ${localizationManager.getLocalizedText('start_screen_desc4')}`;
    desc2.fitSize();
    if (!EntryPoint.mobile) {
      this.animationPlace = this.getChildByName('animationPlace');
      this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_PROGRESS);
      this.animation.state.setAnimation(0, 'start_screen', true);
      this.animationPlace.addChild(this.animation);
      const images = this.getChildByName('images')
      images.visible = false;
    }

    this.overlay = this.getChildByName('overlay');
    this.overlay.alpha = 0.25;

    this.startButton = this.getChildByName('startButton');
    const startButtonLabel = this.startButton.getChildByName('start_screen_start_button');
    startButtonLabel.style.stroke = '#5b0000';
    startButtonLabel.style.strokeThickness = 6;
    makeHitArea(this.startButton);
    this.startButton.enabled = true;

    this.startButton.on('pointertap', this.onStartClick, this);
  }

  changeFrame(value) {
    this.goToFrame(value);
    this.init();
  }

  onStartClick() {
    if (!this.startButton.enabled) return;

    this.startButton.enabled = false;
    GlobalDispatcher.dispatch(eEventTypes.EET_SPIN_CLICK);
  }

  _addStroke(el) {
    el.style.stroke = '#201180';
    el.style.strokeThickness = 10;
    el.style.lineJoin = 'round';
    el.style.miterLimit = 0;
  }

  _complete() {
    gsap.to(this, {
      alpha: 0,
      duration: 1,
      onComplete: () => {
        this.animation && this.animation.destroy({ children: true });
        this.visible = false;
        this.animation = null;
      }
    }).play();

    initWrapperUi();
  }
}
