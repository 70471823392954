import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import { getRandom } from 'Engine/utils/getRandom';
import { lightBlueStrokeStyle } from '../../textStyles/styles';
import WinAmountAnimated from '../winAmountAnimated/winAmountAnimated';
import EntryPoint from 'Engine/EntryPoint';

export default class ControllerExpressions extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.lastWinAmount = 0;
    this._animationRelations = ['boom', 'wow', 'amazing', 'incredible'];

    this.init();
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_COMBINATION_PLAYING, this.show.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_SPIN_NEXT_STAGE, this.reset.bind(this));
  }

  init() {
    this.animationPlace = this.getChildByName('animationPlace');
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_EXPRESSIONS);
    this.animationPlace.addChild(this.animation);
    this.animation.visible = false;
    this.animation.state.addListener({ complete: () => this.animation.visible = false });

    this.winLabel = this.getChildByName('winLabel');
    this.winLabel.text = '';
    this.winLabel.style = { ...this.winLabel.style, ...lightBlueStrokeStyle };

    this.winAnimation = new WinAmountAnimated();
  }

  show({ params }) {
    this.add(params.win_amount);

    EntryPoint.GameModel.isLastCombination(params) && this.showExpression();
  }

  showExpression() {
    if (EntryPoint.GameModel.combinations.length < 2) return;
    let index = EntryPoint.GameModel.combinations.length - 1;
    if (index > Object.keys(this._animationRelations).length - 1) {
      index = Object.keys(this._animationRelations).length - 1;
    }

    this.animation.visible = true;
    this.animation.state.setAnimation(0, this._animationRelations[index], false);
  }

  add(value) {
    const nextAmount = this.lastWinAmount + value;
    this.winAnimation.animate(this.lastWinAmount, nextAmount, EntryPoint.GameModel.currencyInfo.decimals, 300, this.winLabel);
    this.lastWinAmount = nextAmount;

    gsap.to(this.winLabel, { alpha: 1, duration: 0.1 }).play();
  }

  reset() {
    this.winAnimation.stop();
    this.winLabel.alpha = 0;
    this.lastWinAmount = 0;
    gsap.killTweensOf(this.winLabel);
  }
}
