import FlashLib from 'flashlib_onlyplay';
import { createCircleMask, updateCircleMask } from '../../utils/masks';
import { gsap } from 'gsap';
import GameModel from '../../models/gameModel';
import animationCreator from 'Engine/animations/animationCreator';
import eAnimationTypes from '../../enums/eAnimationTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from '../../enums/eEventTypes';
import gameConfig from '../../configs/gameConfig';
import eStateTypes from '../../enums/eStateTypes';
import SoundManager from '../../libs/game-engine/src/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eSoundVolume from '../../sounds/eSoundVolume';

export default class ControllerBomb extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this._explosionAnimationTimeTillCollapse = 1.1;
    this._maskUpdateStartTime = 0;
    this._maskUpdateAnimationTime = 1000;
    this._bombAppearanceTime = 100;
    this._previousProgress = 0;
    this._currentProgress = 0;
    this._active = false;
    this._canUseBomb = false;
    this._animatedBombAppearence = false;
    this._readyToExplode = false;

    this.init();
    this.addListeners();
    this._update();

    this._findBombExplosionMedian = this._findBombExplosionMedian.bind(this);
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_FIELD_DISPLAY_OBJECT, this._updateFieldCoordinates.bind(this));

    GlobalDispatcher.add(eEventTypes.EET_DATA_UPDATED, this._update.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_USE_BOMB, this._setExplosionEnabled.bind(this));

    GlobalDispatcher.add(eEventTypes.EET_BET_CHANGED, this._onBetChanged.bind(this));

    GlobalDispatcher.add(eEventTypes.EET_IDLE_STATE_START, this._tryToActivateBomb.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_ROLLING_STATE_START, this._disable.bind(this));

    GlobalDispatcher.add(eEventTypes.EET_WAIT_USER_INTERACTION_START, this._hide.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_ADD_START, this._hide.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_IN_START, this._hide.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_FREESPINS_OUT_END, this._show.bind(this));
    GlobalDispatcher.add(eEventTypes.EET_BUY_BONUS, () => this._bombBought = true);


    this.timer.on(eEventTypes.EET_BOMB_TIME_END, this._disable.bind(this));
  }

  init() {
    this.portraitWrapper =  this.getChildByName('portraitWrapper');
    if (this.portraitWrapper) {
      this.disabledImage = this.portraitWrapper.getChildByName('disabledBack');
      this.progressBar = this.portraitWrapper.getChildByName('progressBar');
      this.animationPlace = this.portraitWrapper.getChildByName('animationPlace');
      this.tip = this.portraitWrapper.getChildByName('tip');
      this.timer = this.portraitWrapper.getChildByName('timer');
    } else {
      this.disabledImage = this.getChildByName('disabledBack');
      this.progressBar = this.getChildByName('progressBar');
      this.animationPlace = this.getChildByName('animationPlace');
      this.tip = this.getChildByName('tip');
      this.timer = this.getChildByName('timer');
    }

    this._isProgressVisible = false;

    this._createMask();
    this._idleAnimation();
  }

  _update() {
    const bombData = GameModel.currentBomb;
    const progress = bombData.progress;
    this._canUseBomb = bombData.can_use;
    this._canUseBombOnNextSpin = !bombData.can_use && progress === 100;

    if (progress && !this._isProgressVisible) this._showProgress();

    this._maskUpdateStartTime = Date.now();
    this._previousProgress = this._currentProgress;
    this._currentProgress = progress;

    requestAnimationFrame(this._updateMask.bind(this));
  }

  _createMask() {
    createCircleMask(this.progressBar);
  }

  _updateMask() {
    const now = Date.now();
    const diff = now - this._maskUpdateStartTime;
    if (diff >= this._maskUpdateAnimationTime) {
      updateCircleMask(this.progressBar, this._currentProgress);
      if (GameModel.currentState === eStateTypes.EST_IDLE) this._tryToActivateBomb();
      this._tryToHideProgress();
      return;
    }

    updateCircleMask(this.progressBar, this._previousProgress - diff * (this._previousProgress - this._currentProgress) / this._maskUpdateAnimationTime);
    requestAnimationFrame(this._updateMask.bind(this));
  }

  _idleAnimation() {
    if (!this.animation || !this.animation.state) {
      this._initAnimation();
    }

    if (this.animation.state.tracks.length && this.animation.state.tracks[0].animation.name === 'static') return;

    this.animation.state.setAnimation(0, 'static', true);

    if (this._animatedBombAppearence) {
      this.animation.alpha = 0;
      gsap.to(this.animation, {
        alpha: 1,
        duration: this._bombAppearanceTime * 0.01,
      }).play();
    }
  }

  _onBombClick() {
    if (!this._canUseBomb || !this.active) return;
    this._animatedBombAppearence = true;
    this.animation.cursor = null;
    this._canUseBomb = false;
    this.tip.hide();
    this.timer.stop();
    GameModel.useBomb();
    this._processing = true;
    this._bombAction();
  }

  _bombAction() {
    this.animation.state.clearListeners();
    this.animation.state.setAnimation(0, 'idle_fly', true);

    gsap.to(this.animation, {
      ease: 'none',
      motionPath: {
        path: [
          { x: this.animation.x, y: this.animation.y },
          { x: (this.animation.x + this.bombTargetPosition.x) / 2, y: this.bombTargetPosition.y - 300 },
          { x: this.bombTargetPosition.x, y: this.bombTargetPosition.y },
        ],
        alignOrigin: [0.5, 0.5],
      },
      duration: 0.5,
      onComplete: this._tryToExplode.bind(this)
    });
  }

  _setExplosionEnabled() {
    this._readyToExplode = true;
  }

  _tryToExplode() {
    if (this._readyToExplode) {
      this._readyToExplode = false;
      return this._explosion();
    }

    requestAnimationFrame(this._tryToExplode.bind(this));
  }

  _explosion() {
    this.animationTrack = this.animation.state.setAnimation(0, 'action', false);
    this.animation.state.addListener({
      complete: this._reset.bind(this)
    });
    this.animation.autoUpdate = false;
    requestAnimationFrame(this._findBombExplosionMedian);
  }

  _findBombExplosionMedian() {
    this.animation.lastTime = this.animation.lastTime || Date.now();
    let timeDelta = (Date.now() - this.animation.lastTime) * 0.001;
    this.animation.lastTime = Date.now();
    this.animation.update(timeDelta);

    if (this.animationTrack.animationLast >= this._explosionAnimationTimeTillCollapse) {
      SoundManager.play(eSoundType.EST_FIELD_BOMB, eSoundVolume[eSoundType.EST_FIELD_BOMB]);
      GlobalDispatcher.dispatch(eEventTypes.EET_BOMB_ACTION, {
        x: this.bombTargetPosition.x,
        y: this.bombTargetPosition.y,
      });
      this._processing = false;
      this.animation.autoUpdate = true;
      return;
    }

    requestAnimationFrame(this._findBombExplosionMedian);
  }

  _reset() {
    this.active = false;

    this.animation.x = 0;
    this.animation.y = 0;
    this.animation.state.clearListeners();

    this._idleAnimation();
  }

  _updateFieldCoordinates(data) {
    const field = data.params;
    const fieldPositionToLocal = this.animationPlace.toLocal({ x: 0, y: 0 }, field);

    this.bombTargetPosition = {
      x: fieldPositionToLocal.x + (gameConfig.COLUMNS_COUNT * (gameConfig.SYMBOLS.width + gameConfig.SYMBOLS.sideOffset) * field.scale.x / 2) / (this.portraitWrapper ? this.portraitWrapper.scale.x : 1),
      y: fieldPositionToLocal.y + (gameConfig.LINES_COUNT * (gameConfig.SYMBOLS.height + gameConfig.SYMBOLS.topOffset) * field.scale.y / 2) / (this.portraitWrapper ? this.portraitWrapper.scale.x : 1),
    };

    if (this._processing) {
      gsap.killTweensOf(this.animation);
      this.animation.x = this.bombTargetPosition.x;
      this.animation.y = this.bombTargetPosition.y;
    }
  }

  _tryToActivateBomb() {
    if (
      window.OPWrapperService.freeBetsController.isFirstFreeBet
      || window.OPWrapperService.freeBetsController.isActive
    ) return;
    this._canUseBombOnNextSpin && this.tip.show(false, this._bombBought);
    if (this.active || !this._canUseBomb || !GameModel.isBombFeatureActive) return;

    this.active = true;
    this.animation.cursor = 'pointer';
    this.animation.state.setAnimation(0, 'appear', false);
    this.animation.state.addAnimation(0, 'active_pending', true, 0);
    GameModel.showTntTip && this.tip.show();
    this.timer.start();
  }

  _disable() {
    this.tip.hide();
    this._bombBought = false;
    if (!this.active) return;

    this.active = false;
    this.animation.cursor = null;
    this._animatedBombAppearence = false;
    this._idleAnimation();
    this.timer.stop();
  }

  _initAnimation() {
    this.animation = animationCreator.createAnimation(eAnimationTypes.EAT_BOMB);
    this.animationPlace.addChild(this.animation);
    this.animation.interactive = true;
    this.animation.hitArea = new PIXI.Rectangle(
      -this.disabledImage.width / (2 * this.disabledImage.scale.x),
      -this.disabledImage.height / (2 * this.disabledImage.scale.y),
      this.disabledImage.width / this.disabledImage.scale.x,
      this.disabledImage.height / this.disabledImage.scale.y);

    this.animation.on('click', this._onBombClick.bind(this));
    this.animation.on('touchend', this._onBombClick.bind(this));
  }

  _onBetChanged() {
    if (this._processing) return;
    this._currentProgress = 0;
    this._disable();
    this._update();
  }

  _showProgress() {
    this._isProgressVisible = true;

    gsap.to(this.disabledImage, {
      alpha: 0,
      duration: 1,
    }).play();

    gsap.to(this.progressBar, {
      alpha: 1,
      duration: 1,
    }).play();
  }

  _tryToHideProgress() {
    if (this._currentProgress !== 0) return;

    this._isProgressVisible = false;

    gsap.to(this.disabledImage, {
      alpha: 1,
      duration: 1,
    }).play();

    gsap.to(this.progressBar, {
      alpha: 0,
      duration: 1,
    }).play();
  }

  _show() {
    this.visible = true;
  }

  _hide() {
    this.visible = false;
  }

  set active(value) {
    this._active = value;
  }

  get active() {
    return this._active;
  }
}
