import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eEventTypes from '../enums/eEventTypes';

export default class ShowBigWinState extends BaseState {
  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('bigWinContainer:stopState', this.complete, this);
  }

  complete() {
    if (this.active) {
      GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
      super.complete();
    }
  }

  stop() {
    GlobalDispatcher.dispatch(eEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
    super.stop();
  }

  onEnd() {
    super.onEnd();
    EntryPoint.GameModel.spinEndTimestamp = Date.now();
  }
}
